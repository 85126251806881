.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
}
.wrapper {
  max-width: 1300px;
  margin: 0 auto;
}
// .card {
//   // box-shadow: $card-box-shadow;
//   margin-bottom: 30px;
// }

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}
