.sidebarArea {
  flex-shrink: 1;
  width: $sidebarWidth;
  background-color: $sidebarColor;
  font-size: 16px;
  font-weight: 700;
  .sidenav-bg:hover {
    transform: scale(1.05);
    background-color: $gray-200;
    border-radius: $border-radius;
  }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index: 1;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}
.sidebarArea::-webkit-scrollbar {
  width: 10px;
}

.sidebarArea::-webkit-scrollbar-track {
  background: $primary;
}

.sidebarArea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.sidebarArea::-webkit-scrollbar-thumb:hover {
  background-color: $secondary;
}
